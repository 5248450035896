import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue";
var _hoisted_1 = {
    key: 0,
    class: "w-100 d-flex justify-content-center",
    role: "status"
};
var _hoisted_2 = {
    class: "w-100 d-flex justify-content-center",
    style: { "padding-bottom": "7rem" }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _component_inline_svg = _resolveComponent("inline-svg");
    var _component_base_button = _resolveComponent("base-button");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        (!_ctx.currentInfinitePage.isEnd &&
            _ctx.currentInfinitePage.totalPage !== 1 &&
            _ctx.loadMoreLoading.load)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_inline_svg, {
                    src: require('@/assets/svg/loading.svg')
                }, null, 8, ["src"])
            ]))
            : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_2, [
            (!_ctx.currentInfinitePage.isEnd && !_ctx.loadMoreLoading.load)
                ? (_openBlock(), _createBlock(_component_base_button, {
                    key: 0,
                    onMouseenter: _ctx.handleMouseEnter
                }, {
                    default: _withCtx(function () { return [
                        _createTextVNode(_toDisplayString(_ctx.$t("Load More")), 1)
                    ]; }),
                    _: 1
                }, 8, ["onMouseenter"]))
                : _createCommentVNode("", true)
        ])
    ], 64));
}
