import { defineComponent } from "vue";
export default defineComponent({
    name: "BaseInfiniteLoading",
    inheritAttrs: false,
    props: {
        currentInfinitePage: {
            type: Object,
            required: true,
        },
        loadMoreLoading: {
            type: Object,
            required: true,
        },
    },
    setup: function (_, _a) {
        var emit = _a.emit;
        var handleMouseEnter = function () {
            emit("handleMouseEnter");
        };
        return {
            handleMouseEnter: handleMouseEnter,
        };
    },
});
