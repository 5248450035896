import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = { class: "main_div px-5" };
var _hoisted_2 = { class: "position-sticky top-0 z_index_1 w-100 pt-5" };
var _hoisted_3 = { class: "fs_32 fw_700 lh_40" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _component_base_no_product_found = _resolveComponent("base-no-product-found");
    var _component_base_product_card = _resolveComponent("base-product-card");
    var _component_base_infinite_loading = _resolveComponent("base-infinite-loading");
    var _component_base_five_row_col = _resolveComponent("base-five-row-col");
    var _component_base_product_card_skeleton = _resolveComponent("base-product-card-skeleton");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t("Wishlist")), 1)
        ]),
        _createVNode(_Transition, {
            name: "fade",
            mode: "out-in"
        }, {
            default: _withCtx(function () {
                var _a;
                return [
                    (!_ctx.loading && ((_a = _ctx.products) === null || _a === void 0 ? void 0 : _a.length) === 0)
                        ? (_openBlock(), _createBlock(_component_base_no_product_found, {
                            key: 0,
                            loading: _ctx.loading,
                            products: _ctx.products
                        }, null, 8, ["loading", "products"]))
                        : _createCommentVNode("", true)
                ];
            }),
            _: 1
        }),
        _createVNode(_Transition, {
            name: "fade",
            mode: "out-in"
        }, {
            default: _withCtx(function () {
                var _a;
                return [
                    (!_ctx.loading && ((_a = _ctx.products) === null || _a === void 0 ? void 0 : _a.length) > 0)
                        ? (_openBlock(), _createBlock(_component_base_five_row_col, {
                            key: 0,
                            onScroll: _ctx.handleScroll
                        }, {
                            default: _withCtx(function () { return [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products, function (item) {
                                    return (_openBlock(), _createBlock(_component_base_product_card, {
                                        key: item,
                                        product: item,
                                        wishlist: item.is_favourite
                                    }, null, 8, ["product", "wishlist"]));
                                }), 128)),
                                _createVNode(_component_base_infinite_loading, {
                                    currentInfinitePage: _ctx.currentInfinitePage,
                                    loadMoreLoading: _ctx.loadMoreLoading,
                                    onHandleMouseEnter: _ctx.handleMouseEnter
                                }, null, 8, ["currentInfinitePage", "loadMoreLoading", "onHandleMouseEnter"])
                            ]; }),
                            _: 1
                        }, 8, ["onScroll"]))
                        : _createCommentVNode("", true)
                ];
            }),
            _: 1
        }),
        _createVNode(_Transition, {
            name: "fade",
            mode: "out-in"
        }, {
            default: _withCtx(function () { return [
                (_ctx.loading)
                    ? (_openBlock(), _createBlock(_component_base_five_row_col, { key: 0 }, {
                        default: _withCtx(function () { return [
                            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(25, function (index) {
                                return _createVNode(_component_base_product_card_skeleton, { key: index });
                            }), 64))
                        ]; }),
                        _: 1
                    }))
                    : _createCommentVNode("", true)
            ]; }),
            _: 1
        })
    ]));
}
