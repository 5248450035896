import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function (n) { return (_pushScopeId("data-v-15a23f36"), n = n(), _popScopeId(), n); };
var _hoisted_1 = { class: "row translate_center no_product" };
var _hoisted_2 = { class: "text-center" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _component_inline_svg = _resolveComponent("inline-svg");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_inline_svg, {
            src: require('@/assets/svg/no_product_available.svg'),
            width: "250px",
            height: "250px"
        }, null, 8, ["src"]),
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("No Product Found")), 1)
    ]));
}
