import { __awaiter, __generator } from "tslib";
import { defineComponent, onMounted, ref, watch } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { getWishlist, emptyWishlist } from "@/api/WishlistApi";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { topLoadingToggle } from "@/core/helpers/custom";
import { debounce } from "lodash";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { useI18n } from "vue-i18n/index";
export default defineComponent({
    name: "Wishlist",
    setup: function () {
        var _this = this;
        var products = ref([]);
        var product = ref({});
        var loading = ref(false);
        var pagination = ref();
        var store = useStore();
        var t = useI18n().t;
        var loadMoreLoading = ref({
            load: false,
        });
        var currentInfinitePage = ref({
            page: 1,
            isEnd: false,
            totalPage: 0,
        });
        var getListOfWishlist = function (payload) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        loading.value = true;
                        topLoadingToggle(true);
                        return [4 /*yield*/, getWishlist(payload)
                                .then(function (res) {
                                pagination.value = {
                                    page: res.data.meta.current_page,
                                    total: res.data.meta.total,
                                    rowsPerPage: res.data.meta.per_page,
                                    totalPage: res.data.meta.last_page,
                                };
                                products.value = res.data.data;
                            })
                                .catch(function () {
                                "_";
                            })];
                    case 1:
                        _a.sent();
                        loading.value = false;
                        topLoadingToggle(false);
                        return [2 /*return*/];
                }
            });
        }); };
        var clear = function () {
            Swal.fire({
                title: t("Are you sure you want to clear?"),
                showCancelButton: true,
                confirmButtonText: t("Yes"),
                cancelButtonText: t("Cancel"),
                confirmButtonColor: "#00AF27",
            }).then(function (result) {
                if (result.isConfirmed) {
                    emptyWishlist()
                        .then(function () {
                        getListOfWishlist({});
                    })
                        .catch();
                }
            });
        };
        var handleMouseEnter = function () {
            getInfiniteProductList(currentInfinitePage, products);
        };
        var handleScroll = function (e) { return __awaiter(_this, void 0, void 0, function () {
            var element, scrollWidth, fixedHeight, isBottom;
            return __generator(this, function (_a) {
                element = e.currentTarget;
                scrollWidth = parseInt(element.scrollHeight) - parseInt(element.scrollTop);
                fixedHeight = parseInt(element.clientHeight);
                isBottom = scrollWidth >= fixedHeight - 1 && scrollWidth <= fixedHeight + 1;
                if (isBottom) {
                    if (!currentInfinitePage.value.isEnd) {
                        getInfiniteProductList(currentInfinitePage, products);
                    }
                }
                return [2 /*return*/];
            });
        }); };
        var getInfiniteProductList = debounce(function (currentInfinitePage, products) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        loadMoreLoading.value.load = true;
                        return [4 /*yield*/, getWishlist({
                                page: ++currentInfinitePage.value.page,
                            })
                                .then(function (res) {
                                var _a;
                                (_a = products.value).push.apply(_a, res.data.data);
                                var lastPage = res.data.meta.last_page;
                                if (currentInfinitePage.value.page >= lastPage) {
                                    currentInfinitePage.value.isEnd = true;
                                    --currentInfinitePage.value.page;
                                }
                            })
                                .catch(function () {
                                "_";
                            })];
                    case 1:
                        _a.sent();
                        loadMoreLoading.value.load = false;
                        return [2 /*return*/];
                }
            });
        }); }, 250);
        watch(function () { return store.getters.isClearWishlist; }, function (isClear) {
            if (isClear) {
                getListOfWishlist({ page: 1 });
            }
            store.commit(Mutations.REFRESH_CLEAR_WISHLIST, false);
        });
        onMounted(function () {
            setCurrentPageBreadcrumbs("Wishlist", ["Account"]);
            getListOfWishlist({ page: 1 });
        });
        return {
            products: products,
            loading: loading,
            clear: clear,
            pagination: pagination,
            product: product,
            loadMoreLoading: loadMoreLoading,
            currentInfinitePage: currentInfinitePage,
            handleMouseEnter: handleMouseEnter,
            handleScroll: handleScroll,
        };
    },
});
